<template>
  <ion-page >
    <ion-header class="white-background-color"></ion-header>
    <ion-content class="ion-no-padding">
      <div class="a-auth full-height" >
        <div class="ion-text-center profile">
          <h1 class="mb-35">Profile</h1>
          <div class="">
            <p>{{ user.username }}</p>
            <p class="username">{{user.email}}</p>
            <div class="small-divider"></div>
            <div class="ion-padding ion-text-left desc-text">
                <p><b>Deleting user profile is irreversible action.</b></p>
                <p><b>All user data will be deleted!</b></p>
                <p>This action cannot be reverted and all user data will be deleted. You will lose your collection with no option to restore it.</p>
                <p>If you need assistence regarding your user account, contact <u><a href = "mailto: adminAOPC@aicolympic.org">adminAOPC@aicolympic.org</a></u></p>
            </div>
            <div class="ion-padding-horizontal m-width">
              <ion-button class="--default --danger-outline" expand="block" @click="deleteProfileConfirm()">Confirm delete profile</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import {mapGetters} from 'vuex'
  import wAxios from '@/plugins/w/axios'
  import { alertController } from '@ionic/vue'

  const api = {
    deleteProfile: () => wAxios.delete_auth('v1/auth/delete-profile')
  }

  export default {
        computed: {
      ...mapGetters('wAuth', ['user']),
    },

    methods: {
      async deleteProfileConfirm() {
        await this.presentAlertConfirm()
      },

      async presentAlertConfirm() {
        const confirmAlert = await alertController
            .create({
              header: 'Confirm',
              message: 'Are you sure you want to permanently remove this account?',
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                },
                {
                  text: 'Okay',
                  handler: async () => {
                    let isDeleted = await api.deleteProfile()
                    if(isDeleted) {
                      await this.$store.dispatch('wAuth/logout')
                      this.$router.push('login')
                      window.location.reload()
                    }
                    else
                      alert('Error...')
                  },
                },
              ],
            });
        return confirmAlert.present();
      },
    }
  }
</script>

<style lang="sass" scoped>
.profile
  h1
    font-size: 26px
    margin: 0
    padding-top: 70px
    font-weight: bold
    color: black
  p
    margin: 0
    padding-bottom: 30px
    color: black
    &:first-child
      padding-bottom: 10px
  .image-wrapper
    padding-top: 30px
    img
      width: 30%
      padding: 0 10px
      margin: 5px 0
      &:first-child
        border-right: 1px solid rgba(154, 154, 154, 0.2)
  .small-divider
    width: 15%
    height: 1px
    margin: 0 auto
    background: black
    position: relative
    top: -1px
  .username
    font-weight: bold
    border-bottom: 1px solid rgba(154, 154, 154, 0.4)
.mb-35
    margin-bottom: 3.5rem !important

@media only screen and (min-width: 768px)
  img
    display: none


@media only screen and (min-width: 768px)
  img
    max-width: 150px

@media only screen and (max-width: 550px)
  .white-background-color
    background: white
@media only screen and (max-width: 1100px)
  ion-header
    height: 40px

.version
  color: rgba(0,0,0, 0.3)
  font-size: 12px

.m-width
  width: 345px
  text-align: center
  margin: 0 auto

.desc-text
  max-width: 960px
  margin: 0 auto
</style>
